@import "../fonts";

* {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

a {

    text-decoration: none;
}